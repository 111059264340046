import { Box, Grid, Stack, Typography } from "@mui/material";
import profile from "../../res/profile.jpg";

// schöne farbe: #510a32

function Portrait() {
  return (
    <Box
      sx={{
        width: "100%",
        mt: 6,
        mb: 4,
        background:
          "linear-gradient(90deg, rgba(252,232,227,1) 0%, rgba(235,235,235,1) 100%)",
      }}
    >
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        columnSpacing={0}
        rowSpacing={0}
        maxWidth={1000}
        sx={{ mx: "auto", my: 0 }}
      >
        <Grid item xs={10} sm={6} md={6} sx={{ mx: 4 }}>
          <img
            className="Grid-image"
            alt="Profile Jonah Sebright"
            src={profile}
          />
        </Grid>
        <Grid item xs={8} sm={6} md={4} sx={{ my: 3 }}>
          <Stack spacing={2}>
            <Typography variant="h2" color="primary" textAlign="start">
              Jonah Sebright
            </Typography>
            <Typography variant="h4" textAlign="start">
              Software Developer
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Portrait;
