import { Stack } from "@mui/material";
import Offers from "./Offers";
import Portrait from "./Portrait";
import Skills from "./Skills";

function Home() {
  return (
    <Stack spacing={3} alignItems="center">
      <Portrait></Portrait>
      <Offers />
      <Skills />
    </Stack>
  );
}

export default Home;
