import { Box, Grid, Typography } from "@mui/material";
import React from "react";

export interface Detail {
  title: string;
  value: JSX.Element | String;
}
interface DetailsTableProps {
  details: Detail[];
}

export const DetailsTable: React.FC<DetailsTableProps> = ({ details }) => {
  return (
    <Box
      sx={{
        border: "2px solid var(--color-primary)",
        borderRadius: "10px",
        p: 1,
      }}
    >
      <Grid container spacing={1} justifyContent="center" alignItems="start">
        {details.map((detail, index) => (
          <React.Fragment key={index}>
            <Grid item xs={6}>
              <DetailTitle>{detail.title}</DetailTitle>
            </Grid>
            <Grid item xs={6}>
              <DetailValue>{detail.value}</DetailValue>
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
    </Box>
  );
};

const DetailTitle: React.FC<any> = ({ children }) => (
  <Typography>{children}</Typography>
);

const DetailValue: React.FC<any> = ({ children }) => (
  <Typography variant="body1">
    <b>{children}</b>
  </Typography>
);
