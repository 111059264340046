import React from "react";
import { Divider, Stack, Typography } from "@mui/material";
import { linkedInProfile, fiverrProfile } from "../constants";

function Footer() {
  return (
    <Stack component="footer" margin={3} spacing={2}>
      <Divider />
      <Stack
        direction="row"
        spacing={2}
        justifyContent="space-between"
        alignItems="start"
      >
        <Typography variant="body2" color="GrayText">
          {`Copyright © ${new Date().getFullYear()} Jonah Sebright`}
        </Typography>
        <Stack direction="row" spacing={2}>
          <a href={fiverrProfile}>
            <img
              height="24"
              alt="Fiverr Logo"
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/18/Fiverr_Logo_09.2020.svg/1280px-Fiverr_Logo_09.2020.svg.png"
            />
          </a>
          <a href={linkedInProfile}>
            <img
              height="24"
              alt="LinkedIn Logo"
              src="https://cdn-icons-png.flaticon.com/512/174/174857.png"
            />
          </a>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default Footer;
