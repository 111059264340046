import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { Detail, DetailsTable } from "../../components/DetailsTable";
import PageHeading from "../../components/PageHeading";
import SectionHeading from "../../components/SectionHeading";
import familyShoppingImage from "../../res/projects/family_list_framed_EN.png";
import familyShoppingImage2 from "../../res/projects/flour_details_framed_EN.png";
import gradexAndroidImage2 from "../../res/projects/GradeX_android_grade.png";
import gradexAndroidImage from "../../res/projects/GradeX_android_subject.png";
import gradexWebsiteImage from "../../res/projects/GradeX_Website.png";
import gradexFlutterImage from "../../res/projects/Homescreen_framed_EN.png";
import gradexFlutterImage2 from "../../res/projects/Subject_Stats_framed_EN.png";
import tournamentSoftwareImage from "../../res/projects/tournament_software.png";

type Platform = "Mobile (Android)" | "Mobile (Android & iOS)" | "Web";
type Framework = "Android" | "Flutter" | "React" | "Blazor";
type Language = "Java" | "Dart" | "Typescript" | "C#";

interface Backend {
  platform: String;
  language?: Language;
  servicesUsed?: String[];
  totalLines?: number;
}

interface ImageData {
  path: string;
  horizontal: boolean;
  alt: string;
}

interface Project {
  title: String;
  publishedAt?: JSX.Element;
  platform: Platform;
  framework: Framework;
  language: Language;
  uiLibrary: String;
  backend?: Backend;
  totalLines?: number;
  description?: String;
  images: ImageData[];
}

const eduManager: Project = {
  title: "Exam Manager (not real name)",
  platform: "Web",
  framework: "React",
  language: "Typescript",
  uiLibrary: "Material UI",
  backend: {
    platform: "Custom APIs and other services hosted on Azure",
    language: "C#",
  },
  description: `Web app for managing exams & appointments between students and proctors. 
  This app included an admin panel to manage appointments and sending messages to proctors with matching skills/knowledge about an exam. 
  Proctors can then enter their availability and an admin can select a proctor for the exam. 
  Further, the app displays exam results to students.`,
  images: [],
};

const surveyApp: Project = {
  title: "Accidents Survey App (not real name)",
  platform: "Web",
  framework: "Blazor",
  language: "C#",
  uiLibrary: "Blazorise",
  backend: {
    platform: "Custom APIs and other services hosted on Azure",
    language: "C#",
  },
  description: `Web app for collecting and displaying data of accidents. 
  Assigned people of a partner (e.g. a trampoline park in Zurich) can fill in a survey about an accident. 
  Each survey can be customized for a specific partner type
  (e.g. a climbing hall might have a field called "Difficulty" with options red, blue, violet, etc...). 
  A survey also had dynamic fields (some where shown or not shown or had different options depending on the input of other fields). 
  The app also included an admin panel to manage partners and their survey entries that belong to the system.`,
  images: [],
};

const speechDemoApp: Project = {
  title: "Microsoft Speech Demo",
  publishedAt: (
    <>
      as{" "}
      <a
        href="https://github.com/isolutionsag/cognitive-services-speech-demo"
        target="_blank"
        rel="noreferrer"
      >
        Github repository
      </a>
      . Follow the instructions there and run the app locally.
    </>
  ),
  platform: "Web",
  framework: "React",
  language: "Typescript",
  uiLibrary: "Material",
  backend: {
    platform: "Microsoft Cognitive Speech Services",
  },
  totalLines: 1800,
  description: `Web app to showcase abilities of the Microsoft Cognitive Speech Services. 
  This mainly includes realtime transcription from a desired to a desired language and a bot with whom you can have a conversation in various languages. 
  I mostly worked on this project on my own with instructions of features and UI from the company.`,
  images: [],
};

const professionalProjects = [eduManager, surveyApp, speechDemoApp];

const gradexFlutter: Project = {
  title: "GradeX",
  publishedAt: (
    <>
      on{" "}
      <a
        href="https://play.google.com/store/apps/details?id=com.sebright.gradex"
        target="_blank"
        rel="noreferrer"
      >
        PlayStore
      </a>
      , soon on App store.
    </>
  ),
  platform: "Mobile (Android & iOS)",
  framework: "Flutter",
  language: "Dart",
  totalLines: 17000, // 300 files (10.7.2022)
  uiLibrary: "Material Design",
  backend: {
    platform: "Firebase",
    language: "Typescript",
    servicesUsed: [
      "Authentication",
      "Firestore (Database)",
      "Cloud Functions",
      "Remote Config",
      "Messaging",
      "Crashlytics",
      "Analytics",
      "Google Admob",
    ],
    totalLines: 1700, // (10.7.2022)
  },
  images: [
    { path: gradexFlutterImage, horizontal: false, alt: "GradeX Home screen" },
    {
      path: gradexFlutterImage2,
      horizontal: false,
      alt: "GradeX Year Statistics",
    },
  ],
  description: `The new version of the old GradeX app (built only for Android).\n
    GradeX is a grade manager app for students. They can enter their subjects and grades for 
    each school year and view their results (average or plus points) & statistics.
    The also tells them what grade they need in the next exam to achieve their target average. 
    Students have a few settings options and can sort their school years, subjects and grades by various properties. 
    All data is backed up on a database (firestore) and connected to their account on GradeX.`,
};

const familyShopping: Project = {
  title: "Family Shopping",
  platform: "Mobile (Android)",
  framework: "Android",
  language: "Java",
  totalLines: 10000, // 100 files (10.7.2022)
  uiLibrary: "Material Design",
  backend: {
    platform: "Firebase",
    language: "Typescript",
    servicesUsed: [
      "Authentication",
      "Firestore",
      "Cloud Functions",
      "Remote Config",
      "Crashlytics",
      "Analytics",
    ],
    totalLines: 700,
  },
  images: [
    {
      path: familyShoppingImage,
      horizontal: false,
      alt: "Family Shopping List",
    },
    {
      path: familyShoppingImage2,
      horizontal: false,
      alt: "Shopping Item details",
    },
  ],
  description: `Shopping helper app for families or other groups.
  This app allows people to create a shopping group and share their shopping list.
  Other group members can walk into a shop and buy the items for the others. 
  To make sure an item is bought in the correct shop a member can define the correct store when adding an item. 
  When a member enters a shop (e.g. Coop) he can filter for items that should be bought at Coop (or have no shop defined). 
  A group has normal and Admin members that are allowed to manage the group. 
  All data is backed up on a database (firestore) and connected to their account. 
  - The iOS version is currently in progress (built with Flutter).`,
};

const gradexWebsite: Project = {
  title: "GradeX-Website",
  publishedAt: (
    <>
      at{" "}
      <a
        href="https://gradex-pinkduck.web.app"
        target="_blank"
        rel="noreferrer"
      >
        gradex-pinkduck.web.app
      </a>
    </>
  ),
  platform: "Web",
  framework: "React",
  language: "Typescript",
  uiLibrary: "Material UI",
  totalLines: 600,
  images: [
    { path: gradexWebsiteImage, horizontal: true, alt: "GradeX Website" },
  ],
  description: `A website to showcase and promote the GradeX app. It also provides the privacy policy and terms of service.`,
};

const gradexAndroid: Project = {
  title: "GradeX Android (Deprecated)",
  publishedAt: <>on PlayStore</>,
  platform: "Mobile (Android)",
  framework: "Android",
  language: "Java",
  uiLibrary: "Material Design",
  totalLines: 36000, //620 files (10.7.2022)
  backend: {
    platform: "Firebase",
    language: "Typescript",
    servicesUsed: [
      "Authentication",
      "Firestore",
      "Storage",
      "Cloud Functions",
      "Remote Config",
      "Crashlytics",
      "Analytics",
    ],
    totalLines: 2300, // 55 files (10.7.2022)
  },
  images: [
    { path: gradexAndroidImage, horizontal: false, alt: "Subject details" },
    { path: gradexAndroidImage2, horizontal: false, alt: "Grade details" },
  ],
  description: `Old version of GradeX which was published on PlayStore as GradeX 1.0. 
  This version had a few additional features compared to the new version: 
  Images of the exams could be uploaded with a grade. Subjects, grades and images could be starred to find them quickly later on. 
  The app was compatible with the Swiss, German and English or any custom grade system.`,
};

const tournamentSoftware: Project = {
  title: "(Badminton) Tournament Software",
  publishedAt: (
    <>
      at{" "}
      <a
        href="https://turnier-software.web.app/"
        target="_blank"
        rel="noreferrer"
      >
        turnier-software.web.app
      </a>
    </>
  ),
  platform: "Web",
  framework: "React",
  language: "Typescript",
  uiLibrary: "Bootstrap",
  totalLines: 1200,
  backend: {
    platform: "Firebase",
    totalLines: 500,
    language: "Typescript",
    servicesUsed: ["Firestore", "Cloud Functions"],
  },
  description: `Not quite serious project quickly created for my badminton club, where we needed a simple software for to manage a doubles tournament. 
    Some features of this app are not quite finished, as they were not required for our club at that time. 
    The supported features are as follows: Creating a tournament with a list of participants. 
    Generating new rounds where the teams, opponents and bench players are generated (taking into account previous rounds). 
    Displaying a ranking table by the same rules as those of Swiss-Badminton.`,
  images: [
    { path: tournamentSoftwareImage, horizontal: true, alt: "Demo Tournament" },
  ],
};

const personalProjects = [
  gradexFlutter,
  familyShopping,
  gradexWebsite,
  gradexAndroid,
  tournamentSoftware,
];

const rowSpacing = { xs: 3, sm: 4, md: 5 };
const columnSpacing = { xs: 1, sm: 2, md: 4 };

function Projects() {
  return (
    <Box sx={{ maxWidth: "100vw" }}>
      <Stack spacing={6} alignItems="center">
        <PageHeading
          title="Projects"
          description={`This is a collection of my software projects both as a professional 
            working for a company with customers and as individual person with 
            great app ideas. As a professional, I generally worked on a project 
            in a team. Unfortunately, I cannot share too much about or any
            screenshots of those projects for privacy reasons.`}
        />
        <Stack
          spacing={4}
          alignItems="center"
          sx={{ p: 1, pr: 2, maxWidth: "100vw" }}
        >
          <SectionHeading>Professional</SectionHeading>
          <Grid
            container
            justifyContent="start"
            rowSpacing={rowSpacing}
            columnSpacing={columnSpacing}
            alignItems="stretch"
          >
            {professionalProjects.map((project, index) => (
              <Grid item key={index} xs={12} sm={6} md={6} lg={4}>
                <ProjectItem {...project} />
              </Grid>
            ))}
          </Grid>
          <Box sx={{ height: 4 }} />
          <SectionHeading>Personal</SectionHeading>
          <Grid
            container
            justifyContent="center"
            rowSpacing={rowSpacing}
            columnSpacing={columnSpacing}
            alignItems="stretch"
            sx={{ m: 2 }}
          >
            {personalProjects.map((project, index) => (
              <Grid item key={index} md={12} lg={6}>
                <ProjectItem {...project} />
              </Grid>
            ))}
            {personalProjects.length % 2 === 1 && (
              <Grid item md={12} lg={6}></Grid>
            )}
          </Grid>
        </Stack>
      </Stack>
    </Box>
  );
}

export const ProjectItem: React.FC<Project> = (project) => {
  const details: Detail[] = [
    { title: "Platform", value: project.platform },
    { title: "Framework", value: project.framework },
    { title: "Language", value: project.language },
    { title: "UI Library", value: project.uiLibrary },
    { title: "Backend", value: project.backend?.platform ?? "--" },
  ];

  if (project.totalLines) {
    if (project.backend?.language !== project.language) {
      details.push({
        title: `Total lines (${project.language})`,
        value: project.totalLines + "",
      });
    } else {
      details.push({
        title: "Total lines",
        value: project.totalLines + (project.backend?.totalLines ?? 0) + "",
      });
    }
  }

  if (
    project.backend &&
    project.backend.totalLines &&
    project.backend.language !== project.language
  ) {
    details.push({
      title: `Total lines (${project.backend!.language})`,
      value: (project.backend!.totalLines ?? "--") + "",
    });
  }

  if (project.backend?.servicesUsed) {
    details.push({
      title: "Services used",
      value: project.backend?.servicesUsed.join(", "),
    });
  }

  const detailWidth =
    project.images.length === 0 ? 11 : project.images[0]?.horizontal ? 5 : 6;
  const content = (
    <Stack spacing={2}>
      <Typography variant="h5">{project.title}</Typography>
      <Grid container columnSpacing={1} rowSpacing={2} alignItems="start">
        <Grid
          item
          container
          xs={12}
          md={detailWidth}
          rowSpacing={2}
          columnSpacing={2}
        >
          {project.description && (
            <Grid item>
              <Typography variant="body1" color="text.secondary">
                {project.description}
              </Typography>
            </Grid>
          )}
          <Grid item>
            <Box sx={{ mr: 1 }}>
              <DetailsTable details={details} />
            </Box>
          </Grid>
          {project.publishedAt && (
            <Grid item>
              <Typography>Published {project.publishedAt}</Typography>
            </Grid>
          )}
        </Grid>
        {project.images.map((image, index) => (
          <Grid
            item
            key={index}
            xs={image.horizontal ? 12 : 6}
            md={image.horizontal ? 7 : 3}
            sx={{ height: "100%" }}
          >
            <img
              className="Grid-image"
              src={image.path}
              style={{ margin: "auto" }}
              alt={image.alt}
            />
          </Grid>
        ))}
      </Grid>
    </Stack>
  );

  return (
    <Card
      sx={{
        height: "100%",
        margin: "auto",
        backgroundColor: "var(--card-background)",
        boxShadow: "none",
      }}
    >
      <CardActionArea
        onClick={() => {
          /* TODO */
        }}
        sx={{
          height: "100%",
        }}
      >
        <CardContent
          sx={{
            height: "100%",
          }}
        >
          {content}
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default Projects;
